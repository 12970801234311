// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-page-creation-templates-condo-tsx": () => import("./../../../src/page-creation-templates/condo.tsx" /* webpackChunkName: "component---src-page-creation-templates-condo-tsx" */),
  "component---src-page-creation-templates-page-tsx": () => import("./../../../src/page-creation-templates/page.tsx" /* webpackChunkName: "component---src-page-creation-templates-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

